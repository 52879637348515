/*table {*/
/*  border: 1px solid lightgray;*/
/*  padding: 1px;*/
/*}*/

/*tbody {*/
/*  border-bottom: 1px solid lightgray;*/
/*}*/

/*th {*/
/*  border-bottom: 1px solid lightgray;*/
/*  padding: 4px;*/
/*}*/

/*td {*/
/*  padding: 4px;*/
/*}*/

/*tfoot {*/
/*  color: gray;*/
/*}*/

/*tfoot th {*/
/*  font-weight: normal;*/
/*}*/


table {
border-collapse: collapse;
border: 1px solid #ccc;
line-height: 1.5;
}
 th {
padding: 10px;
font-weight: bold;
vertical-align: top;
background: #0099e3;
color: #ffffff;
}
td {
padding: 10px;
vertical-align: top;
height:130px;
}
 tr:nth-child(even) {
background: #cee7ff;
}